// @ts-nocheck
import React, { useContext, useEffect, useRef, useCallback, useState } from 'react'
import CardContent from '@material-ui/core/CardContent'
import ButtonBase from 'components/common/ButtonBase'
import TypographyPro from '../../themes/TypographyPro'
import { inject, observer } from 'mobx-react'
import BadgeBase from '@material-ui/core/Badge'
import {
	isMobile,
	isRTL,
	getLocaleStr,
	isNodeJsEnv,
	codeToLocale,
	getMenuItemPageName,
	getTranslatedTextByKey,
	areAllVariationsHidden,
	getSectionIdForItem,
	getStoreName,
} from 'utils/utils'
import { StoreContext } from '../../contexts/StoreContext'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import IconComponent from '../../themes/IconComponent'
import { sendEnhancedEcommerceEvent } from '../../utils/analytics/analytics'
import { CONSTANTS, ORDER_TYPES } from '../../utils/constants'
import MenuCardMedia from '../MenuCardMedia'
import ItemPrice from './ItemPrice'
import Home from 'mobx/Home'
import kebabCase from 'lodash-es/kebabCase'
import { useRouter } from 'next/router'
import { useStores } from 'hooks/useStores'
import { transformMenuItemToCartItem } from 'mobx/Cart/utils'
import { SnackbarStatus } from 'mobx/Infra/Infra.type'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS

const CartIcon = (props) => <IconComponent asset="/icons/cartMenuItem.svg" {...props} />

const StyledPrice = styled(TypographyPro)`
	color: var(--fonts);
	margin-bottom: 10px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	padding: 0 10px;

	@media (max-width: 576px) {
		margin-bottom: 11px;
	}

	.strikeoutPrice {
		color: var(--inactive);
		text-decoration: line-through;
	}
`

const StyledPricingInformation = styled(TypographyPro)`
	color: var(--inactive);
	white-space: pre;
`

const MenuItemCard = styled.a`
	box-shadow: var(--defaultShadow1);
	border-radius: var(--borderRadiusFrame);
	background-color: var(--clear);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0;
	width: 260px;
	height: 295px;
	cursor: pointer;
	position: relative;

	@media (max-width: 850px) {
		width: 160px;
		height: 214px;
	}

	${({ $isLimitedOffers, $isLimitedOffersVertical }) =>
		$isLimitedOffers &&
		css`
			${$isLimitedOffersVertical && 'margin: unset !important;'}
		`}
`

// used to avoid image overflowing borders when hovering it
const CardMediaContainer = styled.div`
	overflow: hidden;
	border-top-left-radius: var(--borderRadiusFrame);
	border-top-right-radius: var(--borderRadiusFrame);
	width: 100%;
	aspect-ratio: 1.91/1;
`

const CartButton = styled(ButtonBase)`
	height: 55px;
	width: 120px;
	position: absolute;
	bottom: -58px;
	left: 70px;

	& path {
		fill: var(--clear);
	}

	svg {
		height: 30px;
		width: 30px;
	}

	@media (max-width: 850px) {
		width: 90px;
		height: 40px;
		left: 34.75px;
		bottom: -34px;

		svg {
			height: 22px;
			width: 22px;
		}
	}

	${({ $isLimitedOffersVertical }) => $isLimitedOffersVertical && 'position: absolute; top: 100%;'}
`

const MenuItemCardBottomRow = styled.div`
	display: flex;
	flex-direction: ${({ $isLimitedOffers, $isRTL }) => ($isLimitedOffers && $isRTL ? 'row-reverse' : 'row')};
	justify-content: space-between;
	padding-top: 10px;

	@media (max-width: 850px) {
		padding-top: 0;
		height: 114px;
	}
`

const StyledItemTitle = styled(TypographyPro)`
	width: 235px;

	color: var(--strokeMenuTitle);
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${({ $showPricingInformations }) => ($showPricingInformations ? '2' : '3')};
	-webkit-box-orient: vertical;
	text-align: center;

	@media (max-width: 850px) {
		text-indent: initial;
		display: -webkit-box;
		width: 140px;
	}
`

const StyledContent = styled(CardContent)`
	//Top padding of Figma's 15px + 24px reduced from the image from 160 => 136
	padding: ${({ $isLimitedOffersVertical }) => ($isLimitedOffersVertical ? '15px 15px 0px 10px' : '15px 10px 10px 10px')} !important;
	height: 130px;
	position: relative;

	@media (max-width: 850px) {
		//Top padding of Figma's 10px + 15px reduced from the image from 99 => 84
		padding: 10px !important;
		height: 115px;
	}
`

const Badge = styled(BadgeBase)`
	padding: 0;
	@media (max-width: 850px) {
		.MuiBadge-badge {
			height: 13px;
			min-width: 13px;
			padding: 0;
			font-size: 9px;
		}
	}
	z-index: 1;
	font-size: ${({ $isMobile }) => ($isMobile ? '9px' : '16px')};
	position: absolute;
	top: 134px;

	${({ $isRTL }) =>
		$isRTL
			? css`
					left: 35%;
			  `
			: css`
					right: 35%;
			  `};

	@media (max-width: 850px) {
		top: 109px;
	}

	& .MuiBadge-badge {
		background-color: var(--counterCircleBg);
		color: var(--cartCounterFont);
	}
`

const MenuCardStyle = ($isLimitedOffersVertical) => `
	width: 260px;
	// keeping image ratio of 1:1.91 of height:width
	height: calc(260px / 1.91);
	object-fit: unset;
	transition: transform 0.5s;
	&:hover {
		transform: scale(1.1);
		transition: transform 0.3s;
	}
	@media (max-width: 850px) {
		// keeping image ratio of 1:1.91 of height:width
		height: calc(160px / 1.91);
		width: 160px;
	}
	${$isLimitedOffersVertical && 'height: calc(260px / 1.61);'}
`

const MenuItem = inject(
	'User',
	'Application',
	'Infra',
	'ItemAdditions'
)(
	observer((props) => {
		const {
			item,
			limitedOffersStore,
			limitedOffersItemClick,
			User,
			Application,
			Infra,
			ItemAdditions,
			isLimitedOffersVertical = false,
			isGeneratedStatically,
			isStaticMenu,
			openAllStoresAreClosedDialog,
			index,
		} = props

		const { cartStore, addressManagerV2Store } = useStores()
		const { store } = useContext(StoreContext)
		const isLimitedOffers = !!limitedOffersStore
		const isLimitedOfferId = isLimitedOffers || isLimitedOffersVertical

		const router = useRouter()
		const rest = isLimitedOffers ? limitedOffersStore : store.data
		const locale = User.preferredLanguage
			? codeToLocale[User.preferredLanguage]
			: isLimitedOffers && limitedOffersStore.locale
			? limitedOffersStore.locale
			: rest.locale
		const [itemQuantity, setItemQuantity] = useState(0)

		useEffect(() => {
			const newQuantity = cartStore.items?.[item.id]?.reduce((sum, currItem) => sum + currItem.quantity, 0) ?? 0
			setItemQuantity(newQuantity)
		}, [cartStore, cartStore.items, item, item.id, cartStore.items?.[item.id]])

		const itemTitle = getLocaleStr(item.title, locale)
		const hideMenuItemPrice = Infra.appParams?.features?.hidePriceForUnlocalizedItem && (isLimitedOffers || isStaticMenu)

		const getStaticMenuItemPagePath = useCallback(() => {
			const lang = User.preferredLanguage ? User.preferredLanguage : Infra.appParams.l
			const staticItemTitle = getLocaleStr(item.title, codeToLocale[lang] || 'en')
			const staticItemKey = getMenuItemPageName(staticItemTitle, item.id)
			const itemPage = staticItemKey
			const path = `${lang}/menu/${itemPage}`

			return path
		}, [User.preferredLanguage, Infra.appParams.l, item.title, item.id])

		const clickHandler = async (e, showMenuItem = true) => {
			if (Home.areAllStoresClosed() && openAllStoresAreClosedDialog) {
				openAllStoresAreClosedDialog()
				return
			}

			// TODO: this function NEEDS to be refactored in a way that it is exported/imported externally - so it will be possible
			// to perform proper unit testing on it - WITHOUT code duplication. This is basically the logic of adding an item to the cart from a menu.

			const orderType = User.orderType === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
			const eventMetaData = {
				itemTitle,
				sectionTitle: props.sectionTitle,
				currency: rest.currency,
				countryCode: rest.countryCode,
				category_id: getSectionIdForItem(item.id, rest!.sections),
				storeName: getStoreName(store, orderType, Home.locale.msg, addressManagerV2Store),
			}

			if (!isLimitedOffers && isGeneratedStatically) {
				router.push(`/${getStaticMenuItemPagePath()}`)
				return
			}

			if (isLimitedOffers || !showMenuItem) {
				eventMetaData.list = 'limited offers'
				sendEnhancedEcommerceEvent(EEE.sendProductClickEvent, item, eventMetaData, rest, cartStore.getAnalyticsFields())

				// add the initial item to Mobx
				const transformedItem = transformMenuItemToCartItem(item)
				ItemAdditions.postInit(transformedItem)

				if (!isLimitedOffers) {
					if (item.variations && item.variations.length > 0 && areAllVariationsHidden(item.variations, rest)) {
						// this item has ONLY hidden variations so add them to the Mobx object as well
						item.variations.map((_originalVariation, _childIndex) => {
							const _variation = JSON.parse(JSON.stringify(_originalVariation))
							// const _variationTitle = getLocaleStr(_variation.title, locale)
							const [_variationItemId] = _variation.defaults
							const _variationItem = rest.items[_variationItemId] ?? {}

							const itemMap = {
								id: _variationItem.id,
								en_ID: _variationItem.description?.en_ID,
								name: getLocaleStr(_variationItem.title, locale),
								price: (_variation.prices && _variation.prices[_variationItem.id]) || 0, // some items don't have a price since they are free
								hasVariations: !!_variationItem.variations,
								autoSelectedAndHidden: true,
								isVariation: !!props.parentTitle,
								groupHasDefaults: true, // !!props.defaults && Array.isArray(props.defaults) && props.defaults.length > 0, // if this chip belongs to a set of chips that has a default then cannot de-select the chip
							}

							const keys = `${item.id}.variationsChoices.${_childIndex}`
							ItemAdditions.addAdditionNew(keys, itemMap)
						})
					}

					const cartItem = JSON.parse(JSON.stringify(ItemAdditions.getItem()))
					const success = await cartStore.addItem(cartItem)
					if (success) {
						sendEnhancedEcommerceEvent(EEE.sendAddToCartEvent, cartItem, eventMetaData, cartStore.getAnalyticsFields())

						const title = getLocaleStr(rest.items[item.id].title, rest.locale)
						Infra.showSnackbar({
							snackId: isMobile() ? 'checkout-btn' : 'cart',
							message: `'${title}' ${getTranslatedTextByKey('wasAddedToCart')}`,
							status: SnackbarStatus.SUCCESS,
						})
					}
				}

				if (isLimitedOffers) {
					limitedOffersItemClick(item)
				}
			} else if (showMenuItem) {
				const cartItem = transformMenuItemToCartItem(item)
				cartStore.openItemModal({
					item: cartItem,
				})

				eventMetaData.list = 'menu'
				sendEnhancedEcommerceEvent(EEE.sendProductClickEvent, item, eventMetaData, rest, cartStore.getAnalyticsFields())
			}
		}

		/**
		 * If the menu-item does not have options then just add the item to the cart
		 *
		 * @param e
		 */
		const plusClickHandler = (e) => {
			e.preventDefault()
			e.stopPropagation()

			// If all stores are closed continue to the clickHandler()
			if (Home.areAllStoresClosed() && openAllStoresAreClosedDialog) {
				clickHandler(e, false)
				return
			}

			if (isLimitedOffers) {
				clickHandler(e, false)
			} else {
				const itemHasNoVariations =
					(item && !item.variations) ||
					(item && item.variations.length === 0) ||
					(item && item.variations && areAllVariationsHidden(item.variations, rest))
				const showMenuItem = !!(item.variations && item.variations.length > 0 && !itemHasNoVariations)
				clickHandler(e, showMenuItem)
			}
		}

		const imgSrc = item.media && item.media.logo ? item.media.logo : rest.picture

		const ref = useRef()

		// a) vars to track if the menu-item is in the UX 'view' (+/-500px from being in the viewport). This allow us to
		// load the items' images before the item is in the viewport so the user doesn't see the img load.
		const [UXInViewItemRef, UXInView] = useInView({
			threshold: 0, // [0, 0.75, 1],
			root: isNodeJsEnv ? null : document.querySelector('.slider'),
			rootMargin: '500px 500px',
		})

		// b) vars to track the item is in the physical viewport so we can send analytic impressions to GTM
		const [viewPortInViewItemRef, viewportInView] = useInView({
			threshold: [0.7], // fire an impression of a min. of 70% of the item is in the viewport
			root: isNodeJsEnv ? null : document.querySelector('.slider'),
		})

		// Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
		const setRefs = useCallback(
			(node) => {
				// Ref's from useRef needs to have the node assigned to `current`
				ref.current = node
				// Callback refs, like the one from `useInView`, is a function that takes the node as an argument
				UXInViewItemRef(node)
				viewPortInViewItemRef(node)
			},
			[UXInViewItemRef, viewPortInViewItemRef]
		)

		useEffect(() => {
			// if the item is in the viewport then send an analytics impression to GTM
			if (viewportInView) {
				const listType = isLimitedOffers ? 'limited offers' : 'menu'
				sendEnhancedEcommerceEvent(
					EEE.addMenuItemImpression,
					item,
					listType,
					{ ...rest, category_id: getSectionIdForItem(item.id, rest.sections) },
					props.sectionTitle,
					cartStore.getAnalyticsFields()
				)
			}
		}, [viewportInView])

		return (
			<MenuItemCard
				href={`/${getStaticMenuItemPagePath()}`}
				onClick={(e) => e.preventDefault()}
				ref={setRefs}
				$isLimitedOffers={isLimitedOffers}
				$isLimitedOffersVertical={isLimitedOffersVertical}
				data-testid="menu-item-card"
			>
				<CardMediaContainer>
					{UXInView && (
						<MenuCardMedia
							image={imgSrc}
							id={`${Application.page}-page-${isLimitedOfferId ? `lto-${index + 1}` : `item-${kebabCase(itemTitle)}`}-pic`}
							data-testid="menu-item-pic"
							imageFallback={Infra.appParams.logo}
							title={itemTitle}
							clickHandler={clickHandler}
							hideOnError={false}
							styleCss={MenuCardStyle(isLimitedOffersVertical)}
						/>
					)}
				</CardMediaContainer>
				<StyledContent onClick={clickHandler} $isLimitedOffersVertical={isLimitedOffersVertical}>
					<StyledItemTitle
						$isRTL={isRTL(locale)}
						$showPricingInformations={item.description?.pricingInformation}
						variant="h5"
						component="h3"
						title={itemTitle}
						data-testid="menu-item-title"
					>
						{itemTitle}
					</StyledItemTitle>
					{/* need to find solution for the fact that there's no currency when getting offers */}
					<StyledPrice variant="BodyRegular" component="div">
						<ItemPrice
							hideMenuItemPrice={hideMenuItemPrice} // hide prices for non localized menu-items
							displayPrice={item.description?.displayPrice}
							price={item.price}
							currency={rest?.currency || props.currency}
							countryCode={rest.countryCode}
							strikeoutPrice={item.strikeoutPrice}
						/>
						{item.description?.pricingInformation && (
							<StyledPricingInformation variant="BodySmall">{item.description?.pricingInformation}</StyledPricingInformation>
						)}
					</StyledPrice>
					<MenuItemCardBottomRow $isLimitedOffers={isLimitedOffers} $isRTL={isRTL(locale)}>
						<CartButton
							id={`${Application.page}-page-${isLimitedOfferId ? `lto-${index + 1}` : `item-${kebabCase(itemTitle)}`}-cart-icon`}
							onClick={plusClickHandler}
							data-testid="menu-item-cart-button"
							disabled={cartStore.loading}
						>
							<CartIcon $isLimitedOffersVertical={isLimitedOffersVertical} />
						</CartButton>
						{!isLimitedOffers && itemQuantity > 0 && (
							<Badge
								$isMobile={isMobile()}
								$isRTL={isRTL(locale)}
								badgeContent={<span data-testid="menu-item-quantity-badge">{itemQuantity}</span>}
								onClick={plusClickHandler}
								overlap="rectangular"
							/>
						)}
					</MenuItemCardBottomRow>
				</StyledContent>
			</MenuItemCard>
		)
	})
)

export default React.memo(MenuItem)
